@import '~bootstrap-css-only/css/bootstrap.min.css';
@import '~flatpickr/dist/flatpickr.min.css';
@import '~choices.js/public/assets/styles/choices.min.css';
@import './glyphicons-theme';

* {
    box-sizing: unset;
}

html {
    font-size: 16px;
}

body {
    color: unset;
}

input[type='checkbox'],
input[type='radio'] {
    margin: unset;
    padding: unset;
}

a:active,
a:focus,
a:hover {
    text-decoration: unset;
    color: unset;
    outline: unset;
}

label {
    margin-bottom: unset;
}
